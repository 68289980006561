import { createAsyncThunk } from '@reduxjs/toolkit'
import { RoomService } from '../../services/api'
import { Actions } from '../actions'

export const findBoxes = createAsyncThunk(
  Actions.BOX_FIND_ALL,
  async (params: { siteId: number }, { rejectWithValue }) => {
    try {
      return await RoomService.findBoxes(params)
    } catch (e) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const findAvailableModalities = createAsyncThunk(
  Actions.AVAILABLE_MODALITIES,
  async (params: { examRoomId: number }, { rejectWithValue }) => {
    try {
      return await RoomService.findExamRoomModalities(params)
    } catch (e) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
