import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import logo from '@assets/img/logos/logo-ether.png'
import { PrimaryButton } from '@components/buttons'
import DateTime from '@components/DateTime'
import { SpaceHorizontal } from '@components/Spacing'
import { Text } from '@components/texts'
import { AppBar, Box, Button, Icon, Menu, MenuItem, Toolbar } from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import { logout } from '@reducers/authReducer'
import { Room, RoomType, WaitingRoomService } from '@services/api'
import { RootState } from '@state/store'
import { icons } from '@utils/constants'
import { useDispatch, useSelector } from 'react-redux'
const baseUrl = 'https://api.openweathermap.org/data/2.5/weather/?units=metric'

// const useStyles = makeStyles({
//   overload: {
//     animation: '$_blinker 1s linear infinite',
//     color: 'orange',
//   },
//   '@keyframes _blinker': {
//     '60%': {
//       opacity: 0.75,
//     },
//   },
// })

export default function HeaderApp() {
  const history = useHistory()
  const dispatch = useDispatch()

  const user = useSelector(({ auth }: RootState) => auth.user)

  const selectedSite = useSelector(({ site }: RootState) => site.selectedSite)

  const url =
    baseUrl + `&lat=43.2878287&lon=3.4815595&appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}`
  // `&lat=${selectedSite?.lat}&lon=${selectedSite?.lon}&appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}`

  const [temp, setTemp] = useState<Temp>()
  const [icon, setIcon] = useState<string>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorElMember, setAnchorElMember] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  // Get all rooms from site, refresh on site change
  const rooms = useMemo(() => {
    if (!selectedSite) {
      return []
    }

    const { receptions, waitingRooms, offices, examRooms } = selectedSite

    const allRooms: (Room & { type: RoomType })[] = []
    receptions.forEach(room => {
      allRooms.push({ ...room, path: 'receptions', type: RoomType.Reception })
    })
    waitingRooms.forEach(room => {
      allRooms.push({
        ...room,
        path: 'waiting-rooms',
        type: RoomType.WaitingRoom,
      })
    })
    offices.forEach(room => {
      allRooms.push({ ...room, path: 'doctors', type: RoomType.DoctorOffice })
    })
    examRooms.forEach(room => {
      allRooms.push({ ...room, path: 'operators', type: RoomType.ExamRoom })
    })
    return allRooms
  }, [selectedSite])

  const handleClickItemRoom = (e: React.MouseEvent<HTMLElement>, id: number, type: RoomType) => {
    handleClose()
    const room = rooms.find(room => room.id === id && room.type === type)
    if (room) {
      history.push(`/${room.path}/${id}`)
    }
  }

  const handleClickMember = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMember(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseMember = () => {
    setAnchorElMember(null)
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  const [loading, setLoading] = useState(false)

  const handleReset = async () => {
    if (!selectedSite) {
      return
    }
    setLoading(true)
    await WaitingRoomService.resetVisits({
      siteId: selectedSite.id,
    })
    setLoading(false)
    window.location.reload()
  }

  const memberMenuId = 'member-menu'
  const memberMenu = (
    <Menu
      id={memberMenuId}
      anchorEl={anchorElMember}
      keepMounted
      open={Boolean(anchorElMember)}
      onClose={handleCloseMember}
    >
      <MenuItem value="logout" onClick={handleLogout}>
        Logout
      </MenuItem>
    </Menu>
  )

  const roomMenuId = 'room-menu'
  const roomMenu = (
    <Menu
      id={roomMenuId}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {rooms.map((option, i) => (
        <MenuItem
          key={i}
          value={option.id}
          onClick={e => handleClickItemRoom(e, option.id, option.type)}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  )

  useEffect(() => {
    const callInterval = setInterval(() => {
      fetch(url)
        .then(data => data.json())
        .then(data => {
          const { main, weather } = data
          setTemp(main)
          const icon = icons.find(
            icon => icon.day === weather[0].icon || icon.night == weather[0].icon,
          )
          setIcon(icon?.icon)
        })
        .catch(err => console.log(err))
    }, 5000)
    return () => clearInterval(callInterval)
  }, [])

  return (
    <AppBar position="static">
      <Toolbar
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <Box display="flex" alignItems="center" alignContent="flex-start">
          <img src={logo} alt="logo" style={{ width: '130px' }} />
        </Box>

        <Box display="flex" alignItems="center" alignContent="center">
          <DateTime />
          <SpaceHorizontal size={40} />
          <Icon className={icon} style={{ color: '#3f3f3f' }} />
          <SpaceHorizontal size={20} />
          <Text text={`${Math.round(temp?.temp || 0)}º`} style={{ color: '#3f3f3f' }} />
        </Box>

        <Box display="flex" alignItems="center" alignContent="flex-start">
          <PrimaryButton
            type="reset"
            text="Reset demo"
            fullWidth
            color="secondary"
            onClick={() => handleReset()}
            loading={loading}
            style={{ marginRight: '20px' }}
          />
          <Button
            aria-controls={roomMenuId}
            aria-haspopup="true"
            onClick={handleClick}
            startIcon={
              <Icon
                className="fas fa-cube"
                fontSize="default"
                style={{ fontSize: '25px', color: '#3f3f3f' }}
              />
            }
          />
          <Button
            aria-controls={memberMenuId}
            aria-haspopup="true"
            onClick={handleClickMember}
            startIcon={<AccountCircle style={{ fontSize: '28px', color: '#3f3f3f' }} />}
          >
            {user?.firstName}
          </Button>
        </Box>
      </Toolbar>

      {roomMenu}
      {memberMenu}
    </AppBar>
  )
}
