import { RootState } from '@state/store'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import background from '../assets/img/bg-portal.jpg'

export default function MainLayout({ children, ...props }: any) {
  const isAuth = useSelector(({ auth }: RootState) => auth.isAuth)

  if (isAuth) return <Redirect to={{ pathname: '/', state: { from: props.location } }} />

  return (
    <main>
      {children}
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          overflow: 'hidden',
          zIndex: -1,
        }}
      ></div>
    </main>
  )
}
