import HeaderApp from '@components/headers/HeaderApp'
import { Room, RoomType, SocketService, WaitingRoomPatient } from '@services/api'
import {
  getPatients,
  setCurrentCallUrl,
  setPatient,
  setQueue,
} from '@state/reducers/patientReducer'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { RootState } from 'src/state/store'
import { updateBox } from '../state/reducers/roomReducer'

function AppLayout({ children, ...props }: any) {
  const dispatch = useDispatch()
  const isAuth = useSelector(({ auth }: RootState) => auth.isAuth)
  const selectedSite = useSelector(({ site }: RootState) => site.selectedSite)

  useEffect(() => {
    if (selectedSite) {
      dispatch(getPatients(selectedSite.id))
    }
  }, [selectedSite])

  useEffect(() => {
    SocketService.updatePatient(patient => {
      dispatch(setPatient(patient))
    })
    SocketService.updateCalledQueue(
      (queueData: { currentCalled: WaitingRoomPatient; calledQueue: WaitingRoomPatient[] }) => {
        dispatch(setQueue(queueData))
      },
    )
    SocketService.updateAudioUrl((payload: { audioUrl: string }) => {
      dispatch(setCurrentCallUrl(payload.audioUrl))
    })
    SocketService.onRoomUpdate((payload: { room: Room; type: RoomType; isOccupied: boolean }) => {
      dispatch(updateBox({ id: payload.room.id, isOccupied: payload.isOccupied }))
    })
    return () => {}
  }, [])

  if (!isAuth) {
    return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
  }
  return <main style={{ background: '#E2E6F2' }}>{children}</main>
}

export default function MainLayout({ children }: any) {
  return (
    <>
      <HeaderApp />
      <AppLayout>{children}</AppLayout>
    </>
  )
}
