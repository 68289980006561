// tslint:disable
/* eslint-disable */
import { AxiosInstance } from 'axios'
import { socket } from './socket'

export interface IRequestOptions {
  headers?: any
  baseURL?: string
  responseType?: string
}

export interface IRequestConfig {
  method?: any
  headers?: any
  url?: any
  data?: any
  params?: any
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance
}

// Add default options
export const serviceOptions: ServiceOptions = {}

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void,
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  } else {
    throw new Error('please inject yourself instance like axios  ')
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any,
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url }
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  }
  return configs
}

const basePath = ''
export class SocketService {
  /**
   *
   */

  static emitEvent(action: SocketActions, siteId: number, data?: any) {
    socket.emit(action, { ...data, siteId })
  }

  static sendPatientToWaitingRoom(
    siteId: number,
    medicalOrderId: number,
    attributes: Partial<WaitingRoomPatient>,
    accompanying: number,
  ) {
    SocketService.emitEvent(SocketActions.ReceptionSendToWaiting, siteId, {
      medicalOrderId,
      attributes,
      accompanying,
    })
  }
  static setPatientHere(
    siteId: number,
    id: number,
    destination: { room: Partial<Room>; type: RoomType } | null,
  ) {
    SocketService.emitEvent(SocketActions.SetPatientHere, siteId, {
      id,
      destination,
    })
  }
  static callPatient(
    siteId: number,
    medicalOrderId: number,
    destinationRoomId: number,
    destinationRoomType: RoomType,
  ) {
    SocketService.emitEvent(SocketActions.CallPatient, siteId, {
      medicalOrderId,
      destinationRoomId,
      destinationRoomType,
    })
  }
  static callViaSms(
    siteId: number,
    id: number,
    destinationRoomId: number,
    destinationRoomType: RoomType,
  ) {
    SocketService.emitEvent(SocketActions.CallViaSms, siteId, {
      id,
      destinationRoomId,
      destinationRoomType,
    })
  }
  static declareIncident(siteId: number, examRoomId: number) {
    SocketService.emitEvent(SocketActions.DeclareExamRoomIncident, siteId, {
      examRoomId,
    })
  }

  static stopCallPatient(siteId: number) {
    SocketService.emitEvent(SocketActions.StopCallPatient, siteId)
  }
  static startExam(siteId: number, id: number) {
    SocketService.emitEvent(SocketActions.StartExam, siteId, { id })
  }
  static finishExam(
    siteId: number,
    id: number,
    destination: { room: Room; type: RoomType } | null,
  ) {
    SocketService.emitEvent(SocketActions.FinishExam, siteId, { id, destination })
  }
  static cancelExam(siteId: number, id: number) {
    SocketService.emitEvent(SocketActions.CancelExam, siteId, { id })
  }
  static startDoctorInterview(siteId: number, id: number) {
    SocketService.emitEvent(SocketActions.StartDoctorInterview, siteId, { id })
  }
  static finishDoctorInterview(siteId: number, id: number) {
    SocketService.emitEvent(SocketActions.FinishDoctorInterview, siteId, { id })
  }
  static dischargePatient(siteId: number, id: number) {
    SocketService.emitEvent(SocketActions.SetDischarged, siteId, { id })
  }
  static setEmergency(siteId: number, id: number, value: boolean) {
    SocketService.emitEvent(SocketActions.SetEmergency, siteId, { id, value })
  }
  static setVisitAsCanceled(siteId: number, id: number, cancelReason: string) {
    SocketService.emitEvent(SocketActions.SetAsCanceled, siteId, {
      id,
      cancelReason,
    })
  }

  static updatePatient(cb: (data: any) => void) {
    socket.on(SocketActions.UpdatePatient, cb)
  }
  static updateCalledQueue(cb: (data: any) => void) {
    socket.on(SocketActions.UpdateQueue, cb)
  }
  static updateAudioUrl(cb: (data: any) => void) {
    socket.on(SocketActions.UpdateAudioUrl, cb)
  }
  static onRoomUpdate(cb: (data: any) => void) {
    socket.on(SocketActions.RoomUpdate, cb)
  }
  static onCallPatient(cb: (data: any) => void) {
    socket.on('CALL_PATIENT', cb)
  }
  static onUncallPatient(cb: (data: any) => void) {
    socket.on('UNCALL_PATIENT', cb)
  }
  static echo() {
    socket.emit('SEND_MESSAGE', 'Hello from Client')
  }
}

export class QuestionService {
  /**
   *
   */
  static findAll(
    params: {
      examId?: number | null
      medicalOrderId?: number | null
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/questions'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)
      configs.params = {
        examId: params['examId'],
        medicalOrderId: params['medicalOrderId'],
      }
      console.log(configs.params)

      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static createAnswers(
    params: {
      medicalOrderId: number
      body: CreateAnswerDto[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/questions/answers/{medicalOrderId}'
      url = url.replace('{medicalOrderId}', params['medicalOrderId'].toString())

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)

      configs.data = params.body
      axios(configs, resolve, reject)
    })
  }
}

export class RoomService {
  static findBoxes(params: { siteId: number }, options: IRequestOptions = {}) {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/box'
      url = url += `?siteId=${params.siteId}`
      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  static findExamRoomModalities(params: { examRoomId: number }, options: IRequestOptions = {}) {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam-room/{id}/modalities'
      url = url.replace('{id}', params.examRoomId.toString())

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class WaitingRoomService {
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static getTicketNumber(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/ticket-number'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static updatePatient(
    params: {
      /**  */
      body: any
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/'

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static callPatient(
    params: {
      /**  */
      body: WaitingRoomPatient
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/call'

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static uncallPatient(
    params: {
      /**  */
      body: WaitingRoomPatient
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/uncall'

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static findPatients(
    params: {
      /**  */
      siteId: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room' + `?siteId=${params.siteId}`

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      axios(configs, resolve, reject)
    })
  }

  static resetVisits(
    params: {
      /**  */
      siteId: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/reset-visits'

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)
      configs.data = params

      axios(configs, resolve, reject)
    })
  }
}

export class AuthService {
  /**
   *
   */
  static getCaptcha(options: IRequestOptions = {}): Promise<CapchaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/captcha'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/login'

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/logout'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  // static isValidFirstConnection(
  //   params: {
  //     /**  */
  //     accessionNumber: string;
  //     /**  */
  //     birthDate: string;
  //   } = {} as any,
  //   options: IRequestOptions = {}
  // ): Promise<Patient> {
  //   return new Promise((resolve, reject) => {
  //     let url = basePath + '/auth/local/patient/first-connection';

  //     const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
  //     configs.params = { accessionNumber: params['accessionNumber'], birthDate: params['birthDate'] };
  //     let data = null;

  //     configs.data = data;
  //     axios(configs, resolve, reject);
  //   });
  // }
  /**
   *
  //  */
  // static createUserFirstConnection(
  //   params: {
  //     /** requestBody */
  //     body?: LocalUserRegisterPatientDto;
  //   } = {} as any,
  //   options: IRequestOptions = {}
  // ): Promise<LoginResDto> {
  //   return new Promise((resolve, reject) => {
  //     let url = basePath + '/auth/local/patient/first-connection';

  //     const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

  //     let data = params.body;

  //     configs.data = data;
  //     axios(configs, resolve, reject);
  //   });
  // }
  /**
   *
   */
  // static createLocalUserFromEmail(
  //   params: {
  //     /** requestBody */
  //     body?: LocalUserRegisterFromMailDto;
  //   } = {} as any,
  //   options: IRequestOptions = {}
  // ): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     let url = basePath + '/auth/local/verify-mail';

  //     const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

  //     let data = params.body;

  //     configs.data = data;
  //     axios(configs, resolve, reject);
  //   });
  // }
  /**
   *
   */
  static forgottenPassword(
    params: {
      /**  */
      email: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/forgotten-password'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)
      configs.params = { email: params['email'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  // static resetPassword(
  //   params: {
  //     /**  */
  //     id: number;
  //     /** requestBody */
  //     body?: LocalUserResetPasswordDto;
  //   } = {} as any,
  //   options: IRequestOptions = {}
  // ): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     let url = basePath + '/auth/local/{id}/reset-password';
  //     url = url.replace('{id}', params['id'] + '');

  //     const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

  //     let data = params.body;

  //     configs.data = data;
  //     axios(configs, resolve, reject);
  //   });
  // }
  /**
   *
   */
  // static updatePassword(
  //   params: {
  //     /**  */
  //     id: number;
  //     /** requestBody */
  //     body?: LocalUserUpdatePasswordDto;
  //   } = {} as any,
  //   options: IRequestOptions = {}
  // ): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     let url = basePath + '/auth/local/{id}/update-password';
  //     url = url.replace('{id}', params['id'] + '');

  //     const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

  //     let data = params.body;

  //     configs.data = data;
  //     axios(configs, resolve, reject);
  //   });
  // }
  /**
   *
   */
  // static updateEmail(
  //   params: {
  //     /** requestBody */
  //     body?: LocalUserForgetPasswordDto;
  //   } = {} as any,
  //   options: IRequestOptions = {}
  // ): Promise<User> {
  //   return new Promise((resolve, reject) => {
  //     let url = basePath + '/auth/local/update-email';

  //     const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

  //     let data = params.body;

  //     configs.data = data;
  //     axios(configs, resolve, reject);
  //   });
  // }
}

export class SiteService {
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)
      axios(configs, resolve, reject)
    })
  }
}
export class PatientService {
  /**
   *
   */
  static findPatients(
    params: {
      siteId: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room?siteId={id}'
      url = url.replace('{id}', params['siteId'] + '')

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export enum Role {
  'patient' = 'patient',
  'doctor' = 'doctor',
  'admin' = 'admin',
  'super_admin' = 'super_admin',
}
export interface SecurityPreferences {
  /**  */
  failureNumber: number

  /**  */
  lockoutPeriod: number

  /**  */
  inactivityTimeout: number

  /**  */
  twoFAMandatory: boolean

  /**  */
  twoFAMethod: string

  /**  */
  passwordChangesMandatory: boolean

  /**  */
  passwordChangesPeriod: number

  /**  */
  samePasswordAllowed: boolean
}
export interface CapchaDto {
  /**  */
  data: string

  /**  */
  code: string
}

export interface LoginDto {
  /**  */
  email: string

  /**  */
  password: string
}

export interface AccountDto {
  /**  */
  role: AccountRoleEnum

  /**  */
  email: string

  /**  */
  client: number

  /**  */
  phone: string

  /**  */
  security: SecurityPreferences

  /**  */
  id: number

  /**  */
  locked?: boolean
}

export interface Client {
  /**  */
  name: string

  /**  */
  status: number

  /**  */
  sirenNumber: string

  /**  */
  address: string

  /**  */
  postalCode: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */

  user: UserDto

  id: number

  /**  */
  fax: string

  /**  */
  externalId: string

  /**  */
  logo: string

  /**  */
  sites: Site[]

  /**  */
  members: Member[]
}

export interface ExamDto {
  /**  */
  code: string

  /**  */
  label: string

  /**  */
  procedureTime: number

  /**  */
  sex: SexEnum

  /**  */
  instruction: InstructionEnum

  /**  */
  dose: boolean

  /**  */
  contrast: boolean

  /**  */
  shower: boolean

  /**  */
  fasted: boolean

  /**  */
  medication: boolean

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  examRooms: ExamRoom[]
}

export interface QuestionDto {
  /**  */
  id: number

  /**  */
  text: string

  /** */
  type: string

  /** */
  choices: ChoiceDto[]

  /**  */
  askSecondaryIf: string

  /**  */
  childQuestion: QuestionDto

  /**  */
  exams: ({ id: number } & Partial<ExamDto>)[]
}

export interface ChoiceDto {
  /**  */
  id: number

  /**  */
  text: string
}

export interface Member {
  /**  */
  id: number

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  clientId: number

  /**  */
  client: Client
}

export interface Site {
  /**  */
  primary: boolean

  /**  */
  externalId: string

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  address: string

  /** Describe the prefered engine for AWS Polly. Engine define speech's type. */

  /** For a site, describe the prefered language. Store a Language Code for AWS Poly and remember languages available depends of engine type. You can find the languages supported here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/SupportedLanguage.html  */
  preferenceSiteLanguage: string

  /** For a site, describe the prefered voice. Store a VoiceID for AWS Poly and remember voices available depends of engine type and language. You can find the Voices list here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/voicelist.html  */
  preferenceSiteVoice: string

  /**  */
  preferencesDisplaySettingsCallingTime: CallingTimeEnum

  /**  */
  id: number

  /**  */
  clientId: number

  /**  */
  client: Client

  /**  */
  finessNumber: string

  /**  */
  finessStructNumber: string

  /**  */
  status: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string

  /**  */
  geocode: string

  /**  */
  lat: number

  /**  */
  lon: number

  /**  */
  capacity: number

  /**  */
  examRooms: ExamRoom[]

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  offices: Office[]

  /**  */
  receptions: Reception[]

  /**  */
  waitingRooms: WaitingRoom[]

  /**  */
  exams: Exam[]
}

export interface Exam {
  /**  */
  code: string

  /**  */
  label: string

  /**  */
  instruction: InstructionEnum

  /**  */
  dose: boolean

  /**  */
  contrast: boolean

  /**  */
  shower: boolean

  /**  */
  fasted: boolean

  /**  */
  medication: boolean

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /** */
  minWaitMinutes?: number

  /** */
  maxWaitMinutes?: number

  /**  */
  modality: ModalityEnum

  /**  */
  isInjection?: boolean

  /** */
  examRoom_exam: {
    examRoomId: number
    examId: number
  }[]
}

export interface Room {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  path: 'receptions' | 'waiting-rooms' | 'operators' | 'doctors'

  /** */
  isOccupied?: boolean
}
export interface WaitingRoom extends Room {
  /**  */
  reception: Reception

  /**  */
  receptionId: number

  /**  */
  examRooms: ExamRoom[]

  /**  */
  called: Visit[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  capacity: number
}
export interface Box extends Room {
  /** */
  isOccupied: boolean

  /** */
  siteId: number

  /** */
  examRoomId: number

  /**  */
  isWheelchairCompatible: boolean

  /**  */
  isStretcherCompatible: boolean
}

export interface MedicalOrder {
  /**  */
  status: MedicalOrderStatusEnum

  /**  */
  id: number

  /**  */
  visit: Visit

  /**  */
  visitId: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  exam: Exam

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  examId: number

  /**  */
  plannedDate: Date

  /**  */
  editedDate: Date

  /**  */
  cancelDate: Date

  /**  */
  description: string

  /**  */
  studyInstanceUID: string

  /**  */
  accessionNumber: string

  /**  */
  service: string
}

export interface Visit {
  /**  */
  id: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  visitNumber: string

  /**  */
  studies: MedicalOrder[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  admissionDate: Date

  /**  */
  dischargedDate: Date

  /**  */
  ticketNumber: string

  /**  */
  waitingRoomStatus: EnumVisitWaitingRoomStatus

  /**  */
  waitingRoom?: WaitingRoom

  /**  */
  hospitalService: string

  /**  */
  emergency?: boolean

  /**  */
  dementia?: boolean

  /**  */
  visuallyImpaired?: boolean

  /**  */
  blind?: boolean

  /**  */
  crutches?: boolean

  /**  */
  stretcher?: boolean

  /**  */
  wheelchair?: boolean

  /**  */
  deaf?: boolean

  /**  */
  chatRequest?: boolean

  /**  */
  sawDoctor?: boolean
}
export interface Reception extends Room {
  /**  */
  waitingRooms: WaitingRoom[]

  /**  */
  site: Site

  /**  */
  siteId: number
}
export interface Office extends Room {
  /**  */
  site: Site

  /**  */
  siteId: number
}
export interface PrepRoom extends Room {
  /**  */
  status: EnumPrepRoomStatus

  /**  */
  isActive: boolean

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  isWheelchairCompatible: boolean

  /**  */
  isStretcherCompatible: boolean

  /** */
  isOccupied: boolean
}

export interface ExamRoom extends Room {
  /**  */
  shared: boolean

  /**  */
  xrayCompatible: boolean

  /**  */
  mutualized: boolean

  /**  */
  emergency: boolean

  /**  */
  color: string

  /**  */
  active: boolean

  /**  */
  waitingRoom: WaitingRoom

  /**  */
  waitingRoomId: number

  /**  */
  openingTime: number

  /**  */
  recoveryTime: number

  /**  */
  breakTime: number

  /**  */
  closingTime: number

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  exams: Exam[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  isWheelchairCompatible: boolean

  /**  */
  isStretcherCompatible: boolean

  /** */
  parentExamRoomId: number

  /** */
  parentExamRoom: ExamRoom

  /** */
  childExamRooms: ExamRoom[]
}
export interface UserDto {
  /**  */
  role: Role

  /**  */
  email: string

  /**  */
  client: number

  /**  */
  phone: string

  /**  */
  security: SecurityPreferences

  /**  */
  id: number
}
export interface LoginResDto {
  /**  */
  token?: string

  /**  */
  user: UserDto
}

export interface Filter {
  /**  */
  columnName: string

  /**  */
  operation?: string

  /**  */
  value?: string
}

export interface WaitingRoomPatient {
  id: number
  name: string
  examRoomId: number
  examRoom: string
  examRoomCode: string
  waitingRoomId: number
  destinationRoomId: number
  destinationRoomType: RoomType
  plannedDate: Date
  admissionDate: Date
  examCode: string
  examLabel: string
  examId: number
  number: string
  waitingRoomStatus: WaitingRoomStatusEnum
  emergency: boolean
  dementia: boolean
  visuallyImpaired: boolean
  blind: boolean
  crutches: boolean
  stretcher: boolean
  wheelchair: boolean
  deaf: boolean
  chatRequest: boolean
  sawDoctor: boolean
  patientId: number
  patientSex: SexEnum
  patientBirthDate: Date
  siteId: number
  visitId: number
  audioUrls?: { [key: string]: string }
  accompanying: number
  cancelReason: string | null
  answers: AnswerDto[]
  isCalled: boolean
  examInstructions: Instruction[]
  isMultistep: boolean
  steps: Exam[]
  currentStep?: number
  currentRoom?: { room: Room; type: RoomType }
  events: VisitEvent[]
  assignedBoxId?: number
  examModality?: string
  examData: Exam
}

export interface VisitEvent {
  id: number
  createdAt: Date
  step: number | null
  waitingRoomStatus: string
  visitId: number
  currentRoomId: number
  roomType: string
  name?: VisitEventNameEnum
}

export interface Instruction {
  /** */
  label: string
  /** */
  imageUrl: string
}

export interface CreateAnswerDto {
  /** */
  questionId: number
  /** */
  text: string
  /** */
  secondaryAnswer?: string
}

export interface AnswerDto {
  /** */
  id: number
  /** */
  questionId: number
  /** */
  text: string
  /** */
  medicalOrderId: number
  /** */
  question: QuestionDto
  /** */
  secondaryAnswer: string
}

export enum AccountRoleEnum {
  'patient' = 'patient',
  'doctor' = 'doctor',
  'member' = 'member',
  'super-admin' = 'super-admin',
}

export enum InstructionEnum {
  'underwear-male' = 'underwear-male',
  'underwear-female' = 'underwear-female',
  'no-top' = 'no-top',
  'no-pants' = 'no-pants',
  'no-framed-bra' = 'no-framed-bra',
  'no-belt' = 'no-belt',
  'no-shoes-male' = 'no-shoes-male',
  'no-shoes-female' = 'no-shoes-female',
  'no-jewellery-head' = 'no-jewellery-head',
  'no-jewellery-body' = 'no-jewellery-body',
  'no-jewellery-hand' = 'no-jewellery-hand',
  'no-piercing' = 'no-piercing',
  'blouse' = 'blouse',
}

export enum MedicalOrderStatusEnum {
  'available' = 'available',
  'canceled' = 'canceled',
  'completed' = 'completed',
  'discontinued' = 'discontinued',
  'error' = 'error',
  'hold' = 'hold',
  'unspecified' = 'unspecified',
  'replaced' = 'replaced',
  'scheduled' = 'scheduled',
}

export enum SexEnum {
  'A' = 'A',
  'F' = 'F',
  'M' = 'M',
  'N' = 'N',
  'O' = 'O',
  'U' = 'U',
}

export enum EnumVisitWaitingRoomStatus {
  'planned' = 'planned',
  'registered' = 'registered',
  'in-waiting-room' = 'in-waiting-room',
  'in-exam' = 'in-exam',
  'with-doctor' = 'with-doctor',
  'pending-result' = 'pending-result',
  'result-ok' = 'result-ok',
  'discharged' = 'discharged',
  'cancel' = 'cancel',
  'saw-doctor' = 'saw-doctor',
}

export enum EnumPrepRoomStatus {
  'free' = 'free',
  'busy' = 'busy',
}

export enum CallingTimeEnum {
  '5s' = '5s',
  '10s' = '10s',
  '15s' = '15s',
}

export enum SocketActions {
  ReceptionSendToWaiting = 'ReceptionSendToWaiting',
  WaitingCallPatient = 'WaitingCallPatient',
  UpdatePatient = 'UpdatePatient',
  CallPatient = 'CallPatient',
  StopCallPatient = 'StopCallPatient',
  UpdateQueue = 'UpdateQueue',
  StartExam = 'StartExam',
  CancelExam = 'CancelExam',
  FinishExam = 'FinishExam',
  StartDoctorInterview = 'StartDoctorInterview',
  FinishDoctorInterview = 'FinishDoctorInterview',
  UpdateAudioUrl = 'UpdateAudioUrl',
  SetPatientHere = 'SetPatientHere',
  SetDischarged = 'SetDischarged',
  SetEmergency = 'SetEmergency',
  CallViaSms = 'CallViaSms',
  DeclareExamRoomIncident = 'DeclareExamRoomIncident',
  SetAsCanceled = 'SetAsCanceled',
  RoomUpdate = 'RoomUpdate',
}

export enum WaitingRoomStatusEnum {
  PLANNED = 'planned',
  REGISTERED = 'registered',
  IN_WAITING_ROOM = 'in-waiting-room',
  IN_EXAM = 'in-exam',
  WITH_DOCTOR = 'with-doctor',
  PENDING_RESULT = 'pending-result',
  RESULT_OK = 'result-ok',
  DISCHARGED = 'discharged',
  CANCEL = 'cancel',
  SAW_DOCTOR = 'saw-doctor',
  PREPARING_FOR_EXAM = 'preparing_for_exam',
  PENDING_RESCHEDULE = 'pending_reschedule',
  EXAM_CANCELED = 'exam_canceled',
}

export enum RoomType {
  PrepRoom = 'PrepRoom',
  ExamRoom = 'ExamRoom',
  DoctorOffice = 'DoctorOffice',
  WaitingRoom = 'WaitingRoom',
  Reception = 'Reception',
  Box = 'Box',
}

export enum ModalityEnum {
  AR = 'AR',
  AS = 'AS',
  ASMT = 'ASMT',
  AU = 'AU',
  BDUS = 'BDUS',
  BI = 'BI',
  BMD = 'BMD',
  CD = 'CD',
  CF = 'CF',
  CP = 'CP',
  CR = 'CR',
  CS = 'CS',
  CT = 'CT',
  DD = 'DD',
  DF = 'DF',
  DG = 'DG',
  DM = 'DM',
  DOC = 'DOC',
  DS = 'DS',
  DX = 'DX',
  EC = 'EC',
  ECG = 'ECG',
  EPS = 'EPS',
  ES = 'ES',
  FA = 'FA',
  FID = 'FID',
  FS = 'FS',
  GM = 'GM',
  HC = 'HC',
  HD = 'HD',
  IO = 'IO',
  IOL = 'IOL',
  IVOCT = 'IVOCT',
  IVUS = 'IVUS',
  KER = 'KER',
  KO = 'KO',
  LEN = 'LEN',
  LP = 'LP',
  LS = 'LS',
  MA = 'MA',
  MG = 'MG',
  MR = 'MR',
  MS = 'MS',
  NM = 'NM',
  OAM = 'OAM',
  OCT = 'OCT',
  OP = 'OP',
  OPM = 'OPM',
  OPR = 'OPR',
  OPT = 'OPT',
  OPV = 'OPV',
  OSS = 'OSS',
  OT = 'OT',
  PLAN = 'PLAN',
  PR = 'PR',
  PT = 'PT',
  PX = 'PX',
  REG = 'REG',
  RESP = 'RESP',
  RF = 'RF',
  RG = 'RG',
  RTDOSE = 'RTDOSE',
  RTIMAGE = 'RTIMAGE',
  RTPLAN = 'RTPLAN',
  RTRECORD = 'RTRECORD',
  RTSTRUCT = 'RTSTRUCT',
  RWV = 'RWV',
  SEG = 'SEG',
  SM = 'SM',
  SMR = 'SMR',
  SR = 'SR',
  SRF = 'SRF',
  ST = 'ST',
  STAIN = 'STAIN',
  TG = 'TG',
  US = 'US',
  VA = 'VA',
  VF = 'VF',
  XA = 'XA',
  XC = 'XC',
  IN = 'IN',
  IR = 'IR',
  RA = 'RA',
  SC = 'SC',
  SYRINGE = 'SYRINGE',
  INHA = 'INHA',
}

export enum VisitEventNameEnum {
  EXAM_STARTED = 'EXAM_STARTED',
}
