import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Box, ModalityEnum, WaitingRoom, WaitingRoomPatient } from '@services/api'
import { isInWaitingRoom } from '@utils/helpers'
import { findAvailableModalities, findBoxes } from '../thunks/roomThunk'

export interface WaitingRoomWithOccupancy extends WaitingRoom {
  percentage: number
}

export interface RoomState {
  rooms: WaitingRoomWithOccupancy[]
  boxes: Box[]
  modalities: ModalityEnum[]
}

const initialState: RoomState = {
  rooms: [],
  boxes: [],
  modalities: [],
}

const roomReducer = createSlice({
  name: 'roomReducer',
  initialState,
  reducers: {
    setWaitingRoomOccupancy: (
      state,
      action: PayloadAction<{
        waitingRooms: WaitingRoom[]
        patients: WaitingRoomPatient[]
        receptionId: number
      }>,
    ) => {
      const { waitingRooms, patients, receptionId } = action.payload
      const rooms: WaitingRoomWithOccupancy[] = []

      waitingRooms.forEach(_room => {
        if (_room.receptionId === receptionId) {
          rooms.push({ ..._room, percentage: 0 })
        }
      })

      patients.forEach(_patient => {
        if (!isInWaitingRoom(_patient)) {
          return
        }
        const index = rooms.findIndex(_room => _room.id === _patient.waitingRoomId)
        if (index === -1) {
          return
        }
        rooms[index].percentage += 1 + _patient.accompanying
      })
      rooms.forEach(_room => {
        _room.percentage = Math.floor((_room.percentage / _room.capacity) * 100)
      })
      state.rooms = rooms
    },
    clearState: state => {
      state.rooms = []
    },
    updateBox: (state, { payload }: PayloadAction<Partial<Box>>) => {
      const index = state.boxes.findIndex(b => b.id === payload.id)
      console.log({ index })

      if (index !== -1) {
        const obj = state.boxes[index]
        state.boxes[index] = { ...obj, ...payload }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(findBoxes.fulfilled, (state, action: PayloadAction<any>) => {
        state.boxes = action.payload
      })
      .addCase(findAvailableModalities.fulfilled, (state, action: PayloadAction<any>) => {
        state.modalities = action.payload
      })
  },
})
export const { setWaitingRoomOccupancy, clearState, updateBox } = roomReducer.actions

export default roomReducer.reducer
