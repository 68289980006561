export enum Actions {
  /** Question thunks */
  QUESTION_FIND_ALL = 'question/findAll',
  QUESTION_FIND_ONE = 'question/findOne',
  QUESTION_UPDATE = 'question/update',
  QUESTION_CREATE = 'question/create',
  QUESTION_REMOVE = 'question/remove',
  QUESTION_ANSWER_CREATE = 'QUESTION_ANSWER_CREATE',
  BOX_FIND_ALL = 'room/findAll',
  AVAILABLE_MODALITIES = 'room/availableModalities',
}
