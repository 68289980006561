import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    fontWeight: 600,
    color: '#3f3f3f',
  },
})

export const DateTime = () => {
  const classes = useStyles()

  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }
  })

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={5} className={classes.root}>
        {date.toLocaleTimeString('fr-FR')}
      </Grid>
      <Grid item xs={2} className={classes.root}></Grid>
      <Grid item xs={5} className={classes.root}>
        {date.toLocaleDateString()}
      </Grid>
    </Grid>
  )
}

export default DateTime
