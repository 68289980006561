import { createTheme } from '@material-ui/core'

export default createTheme({
  palette: {
    // type: 'dark',
    primary: {
      main: '#3f3f3f',
      light: '#3f3f3f',
      dark: '#3f3f3f',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E91E63',
      dark: '#b0003a',
      light: '#ff6090',
      contrastText: '#fff',
    },

    // text: {
    //   // primary: '#464855',
    //   primary: '#000',
    //   secondary: '#fff',
    // },
  },
  typography: {
    fontFamily: [
      'Rubik',
      'Arial',
      'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontStyle: 'medium',
      fontSize: 100,
    },
    h2: {
      fontStyle: 'medium',
      fontSize: 60,
    },
    h3: {
      fontStyle: 'regular',
      fontSize: 40,
    },
    h4: {
      fontStyle: 'regular',
      fontSize: 30,
    },
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },

    MuiContainer: {
      root: {
        display: 'flex',
      },
    },
    MuiIcon: {
      root: {
        overflow: 'visible',
      },
    },
    MuiButton: {
      root: {
        height: 50,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        fontSize: '1em',
        textTransform: 'uppercase',
        padding: '0px 15px 0px 15px',
      },
      stickyHeader: {
        color: '#3f3f3f',
        padding: '10px',
        backgroundColor: '#fff',
        borderBottom: '1px solid #3a3a3a40',
      },
      body: {
        borderBottom: '1px solid #3a3a3a40',
      },
    },

    MuiTabs: {
      flexContainer: {
        backgroundColor: '#fff',
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
        overflow: 'auto',
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#d9e1f780',
          height: '80px',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#fefefe',
          height: '80px',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: 'grey',
      },
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
      fullWidth: true,
    },
  },
})
