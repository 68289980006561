import { Spinner } from '@components/loadings'
import { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Notifier from './containers/Notifier'
import AuthLayout from './layouts/AuthLayout'
import MainLayout from './layouts/MainLayout'
import { RootState } from './state/store'
import { findBoxes } from './state/thunks/roomThunk'

const authRoutes = [
  {
    path: '/auth/login',
    component: lazy(() => import('./containers/auth/Login')),
  },
  {
    path: '*',
    component: lazy(() => import('./containers/Home')),
  },
]

const mainRoutes = [
  {
    path: '/receptions/:value',
    component: lazy(() => import('./containers/app/reception/Reception')),
  },
  {
    path: '/operators/:value',
    component: lazy(() => import('./containers/app/operator/Operator')),
  },
  {
    path: '/doctors/:value',
    component: lazy(() => import('./containers/app/doctor/Doctor')),
  },
  {
    path: '/waiting-rooms/:value',
    component: lazy(() => import('./containers/app/waitingRoom/WaitingRoom')),
  },
  {
    path: '*',
    component: lazy(() => import('./containers/Home')),
  },
]

export default function App() {
  const selectedSite = useSelector((state: RootState) => state.site.selectedSite)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!selectedSite) {
      return
    }
    dispatch(findBoxes({ siteId: selectedSite.id }))
  }, [dispatch, selectedSite])

  return (
    <>
      <Router>
        <Switch>
          <Route path="/auth/:path" exact>
            <AuthLayout>
              <Switch>
                {authRoutes.map(({ path, component: Component }, index) => (
                  <Route
                    key={index}
                    path={path}
                    render={props => (
                      <Suspense fallback={<Spinner />}>
                        <Component {...props} />
                      </Suspense>
                    )}
                  />
                ))}
              </Switch>
            </AuthLayout>
          </Route>
          <Route>
            <MainLayout>
              <Switch>
                {mainRoutes.map(({ path, component: Component }, index) => (
                  <Route
                    exact
                    path={path}
                    key={index}
                    render={props => (
                      <Suspense fallback={<Spinner />}>
                        <Component {...props} />
                      </Suspense>
                    )}
                  />
                ))}
              </Switch>
            </MainLayout>
          </Route>
        </Switch>
      </Router>
      <Notifier />
    </>
  )
}
