import { Button, ButtonProps, CircularProgress, makeStyles, Theme } from '@material-ui/core'

import { useHistory } from 'react-router'
import { Text } from '@components/texts'

export type ButtonBaseProps = {
  text: string
  loading?: boolean
  link?: string
  textColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'initial'
    | 'error'
    | 'white'
  textStyle?: React.CSSProperties
} & ButtonProps

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: '1rem',
    fontWeight: 500,

    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
      fontWeight: 400,
    },
  },
}))
/**
 * This button is a base for all custom button
 * It should not be exported directly
 * @param text
 * @param loadings
 * @param link
 * @returns
 */
export function ButtonBase({
  text,
  loading,
  link,
  // textColor = 'textSecondary',
  textStyle,
  ...props
}: ButtonBaseProps) {
  const history = useHistory()
  const classes = useStyles()
  return (
    <Button
      {...props}
      variant={props.variant ? props.variant : !link ? 'contained' : undefined}
      disabled={props.disabled || loading}
      startIcon={loading ? <CircularProgress size={20} /> : props.startIcon}
      onClick={link? () => history.push(link):props.onClick}
      style={{borderRadius: 15}}
    >
      <Text
        className={classes.text}
        text={text}
        style={{
          ...textStyle,
        }}
        //  color={textColor}
      />
    </Button>
  )
}
